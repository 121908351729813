import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { nanoid } from 'nanoid';
import GatsbyImage from 'gatsby-image';

import { Layout, SEO, Hero, ContactSection } from '../components';
import ProjectCarousel from '../components/ProjectCarousel';
import { useGraphQL } from '../hooks';

function IndexPage() {
  const projects = useStaticQuery(graphql`
    {
      project1: file(name: { eq: "project-1" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
        id
      }
      project2: file(name: { eq: "project-2" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
        id
      }
      project3: file(name: { eq: "project-3" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
        id
      }
      project4: file(name: { eq: "project-4" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
        id
      }
      project5: file(name: { eq: "project-5" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
        id
      }
      project6: file(name: { eq: "project-6" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
        id
      }
    }
  `);

  const projArray = [
    {
      img: projects.project1,
      description:
        'Power & NBN for multiple subdivision from Mudgee to Broken Hill up to Tweed Heads & south to Forster.',
    },
    {
      img: projects.project2,
      description: 'Installation of new overhead power pole in Guyra NSW.',
    },
    {
      img: projects.project3,
      description:
        'Installation of new Streetlights at the Moore Creek Road roundabout.',
    },
    {
      img: projects.project4,
      description:
        'Installation of 4 way ground service column see-saw baseplate streetlight Cnr of Peel St & Marius St Tamworth NSW.',
    },
    {
      img: projects.project5,
      description:
        'Installation of new street lighting for Hastings River Drive Port Macquarie NSW.',
    },
    {
      img: projects.project6,
      description: 'Installation of new under ground cable.',
    },
  ];

  return (
    <Layout>
      <SEO title="Home" />
      <HeroSection />
      <WhatWeAreAbout />
      <OurServices />
      <ProjectCarousel allFile={projArray} />
      <TermsOfTrade />
      <ContactSection />
    </Layout>
  );
}

function HeroSection() {
  const { homeHero } = useGraphQL();
  return (
    <Hero image={homeHero.childImageSharp.fluid}>
      <div className="w-full space-y-4 text-center">
        <h1 className="text-3xl text-white uppercase sm:text-4xl text-shadow">
          Get in touch for <br />
          <span className="heading-1">a free quote</span>
        </h1>
        <Link to="/contact/" className="text-white button">
          Contact Us
        </Link>
      </div>
    </Hero>
  );
}

function OurServices() {
  const {
    service1,
    service2,
    service3,
    service4,
    service5,
    service6,
  } = useGraphQL();

  const services = [
    {
      label: 'Installation of major power lines',
      image: service1,
      slug: '/services/',
    },
    {
      label: 'All Level 1 electrical works',
      image: service2,
      slug: '/services/',
    },
    {
      label: 'Provide Level 3 electrical designs',
      image: service3,
      slug: '/services/',
    },
    {
      label: 'Level 2 electrical connection',
      image: service4,
      slug: '/services/',
    },
    {
      label: 'Design & construct NBN pit & pipe',
      image: service5,
      slug: '/services/',
    },
    {
      label: 'Excavation works',
      image: service6,
      slug: '/services/',
    },
  ];

  return (
    <article className="relative bg-grey">
      <div className="w-full px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold tracking-wider text-center text-white uppercase">
          Our services
        </h2>
        <ul className="grid gap-8 mt-8 md:grid-cols-3">
          {services.map((service) => (
            <li key={nanoid()} className="w-full max-w-sm mx-auto">
              <Link to={service.slug} className="group">
                <div className="relative h-0 transition duration-300 ease-in-out aspect-ratio-square group-hover:opacity-75">
                  <div className="absolute inset-0 flex bg-white">
                    <GatsbyImage
                      fluid={service.image.childImageSharp.fluid}
                      className="flex-1"
                    />
                  </div>
                </div>
                <h3
                  dangerouslySetInnerHTML={{ __html: service.label }}
                  className="mt-6 text-2xl font-bold tracking-wider text-center text-white uppercase duration-300 ease-in-out group-hover:text-light-blue ransition "
                />
              </Link>
            </li>
          ))}
        </ul>
        <div className="flex justify-center">
          <Link
            to="/services/"
            className="inline-block px-4 py-2 mt-8 font-bold text-center uppercase transition duration-300 ease-in-out border text-light-blue border-light-blue hover:bg-light-blue hover:text-white "
          >
            Learn more
          </Link>
        </div>
      </div>
    </article>
  );
}

function WhatWeAreAbout() {
  const { whatWeAreAboutHero } = useGraphQL();
  return (
    <article className="relative bg-white">
      <div className="w-full px-4 py-8 mx-auto md:py-16 max-w-7xl sm:px-6 lg:px-8">
        <div className="grid justify-center gap-8 sm:gap-14 md:grid-cols-2">
          <div className="w-full mx-auto max-w-prose">
            <div className="relative h-0 aspect-ratio-square">
              <div className="absolute inset-0 flex bg-sky-blue">
                <GatsbyImage
                  fluid={whatWeAreAboutHero.childImageSharp.fluid}
                  className="flex-1"
                />
              </div>
            </div>
          </div>
          <div className="flex items-center md:py-12">
            <div>
              <h2 className="text-2xl font-bold tracking-wider text-center uppercase text-light-blue md:text-left">
                What we are about
              </h2>

              <p className="mt-2">
                Midstate Power Pty Ltd originated in Mudgee NSW in 2014 as a
                Level 1 Accredited Service Provider, before moving our
                operations to Tamworth NSW due to the high demand. This has
                allowed Midstate Power to service greater regional NSW including
                the far North Coast where we now have a depot as well.
              </p>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

function TermsOfTrade() {
  const { termsHero, allSanityFileAsset } = useGraphQL();
  console.log(allSanityFileAsset);
  return (
    <article className="relative bg-light-blue">
      <div className="w-full mx-auto max-w-7xl">
        <div
          style={{ maxHeight: '30rem' }}
          className="flex flex-col justify-center space-y-8 md:space-y-0 md:flex-row-reverse"
        >
          <div
            style={{ marginRight: '-0.01rem' }}
            className="w-full max-w-6xl mx-auto md:w-2/3"
          >
            <GatsbyImage
              fluid={termsHero.childImageSharp.fluid}
              className="flex-1"
              imgStyle={{ minHeight: '25rem', maxHeight: '30rem' }}
            />
          </div>
          <div className="flex items-center justify-center w-full px-4 md:py-12 md:w-1/3 md:pr-7">
            <div className="text-center sm:text-left">
              <h2 className="text-2xl font-bold tracking-wider text-white uppercase md:text-left">
                Read our terms
                <br />
                of trade here
              </h2>
              <a
                href="/documents/terms-of-trade.pdf"
                className="inline-block px-4 py-2 mt-8 mb-12 font-bold text-center text-white transition duration-300 ease-in-out bg-black border sm:mb-48 md:mb-0 border-light-blue hover:text-light-blue "
              >
                Download here
              </a>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export default IndexPage;
